import React, { useState } from 'react'
import logo from '../assets/logo.png'
import hamburgerMenu from '../assets/hamburgerMenu.svg'
import lock from '../assets/lock.svg'
import close from '../assets/close.svg'

const Navbar = () => {

  const [toggle,setToggle]=useState(false)
  const handleClick = ()=> setToggle(!toggle)

  return (
    <div className='w-full h-[80px] bg-transparent hover:bg-[#2a3676] transition-colors duration-200" border-b font-brandon-grotesque'>
      <div className='md:max-w-[1480px] max-w-[600px] m-auto w-full h-full flex justify-between items-center md:px-0 px-4'>
          
        <img src={logo} alt="Muslim Study Abroad Logo" className="py-3 h-[90px]" />
          
        <div className='hidden md:flex items-center'>
            <ul className='flex gap-10 text-white text-shadow'>
                <li>HOME</li>
                <li>PROGRAMS</li>
                <li>SCHOLARSHIPS</li>
                <li>SUPPORT</li>
                <li>ABOUT</li>
            </ul>
        </div>
        <div className='hidden md:flex'>
            <button className='flex justify-between items-center  bg-transparent  px-6 gap-1 text-white text-shadow'>
                <img src={lock} alt='a lock'/>
                Login
            </button>
            <button className='px-4 py-3 rounded-md bg-[#2a3676] text-white font-bold'>Sign Up</button>
        </div>
        <div className='md:hidden'  onClick={handleClick}>
                <img src={toggle?close: hamburgerMenu } alt='toggle' />
        </div>
      </div>
      <div className={toggle?'absolute z-10 p-4 bg-white w-full md:hidden border-b':'hidden'}>
        <ul>
          <li className='p-4 text-black'>HOME</li>
          <li className='p-4 text-black'>PROGRAMS</li>
          <li className='p-4 text-black'>SCHOLARSHIPS</li>
          <li className='p-4 text-black'>SUPPORT</li>
          <li className='p-4 text-black'>ABOUT</li>
          <div className='flex flex-col my-4 gap-4'>
              <button className='border border-[20B486] flex justify-center items-center  bg-transparent  px-6 gap-2 py-4'>
                <img src={lock} alt='lock' />
                Login
              </button>
              <button className='px-4 py-5 rounded-md bg-[#2a3676] text-white font-bold'>Sign Up For Free</button>
          </div>
        </ul>
      </div>
    </div>
  )
}

export default Navbar