import React from 'react';
import { Hero } from './components';
import './App.css';


const App = () => {
  return (
    <div>
      <Hero />
    </div>
  );
}

export default App;