import React, { useEffect, useState } from 'react'
import { MdOutlineEmail } from "react-icons/md";
import  video0  from '../assets/overlay/0.mp4'
import  video1  from '../assets/overlay/1.mp4'
import  video2  from '../assets/overlay/2.mp4'
import poster0 from '../assets/0.png'
import poster1 from '../assets/1.png'
import poster2 from '../assets/2.png'
import '../index.css'
import Navbar from './Navbar';


const backgroundVideos = {
  0: video0,
  1: video1,
  2: video2
}

const backgroundPosters = {
  0: poster0,
  1: poster1,
  2: poster2
}
const Hero = () => {
  const [randomNumber, setRandomNumber] = useState(0)
  useEffect(() => {
    setRandomNumber(Math.floor(Math.random() * 3))
    console.log(randomNumber);
  }, [randomNumber])

  
  return (
    <div class="relative hero min-h-screen">
      <Navbar className="z-10" />
      <video class="-z-2 absolute inset-0 w-full h-full object-cover" poster={backgroundPosters[randomNumber]} autoPlay muted loop playsInline>
        <source src={backgroundVideos[randomNumber]} type="video/mp4" />
      </video>
      <div class="-z-2 absolute inset-0 bg-black opacity-50"></div>      
         <div className='md:max-w-[1480px] m-auto grid md:grid-cols-2 max-w-[600px]  px-4 md:px-0'>
              
          <div className='py-8 px-4 flex flex-col justify-start gap-4'>
            <div class="pt-32">
            <div class="text-shadow">
             <p className='py-2 text-2xl text-white font-medium'>MUSLIM STUDY ABROAD</p>
             <h1 className='font-custom text-white md:leading-[72px] py-2 md:text-6xl text-5xl'>Join A Muslim Study Abroad Adventure Today!</h1>
             <p className='font-custom text-white py-2 text-lg'>A place where students of all backgrounds are invited to embark on an enriching journey of cross-cultural exploration and academic advancement</p>
            </div>
             <form className='bg-white border max-w-[500px] p-4 input-box-shadow rounded-md flex justify-between'>
               <input 
                 className='bg-white w-20'
                 type="email"
                 placeholder='Submit email address'
               />
               <button>
                 <MdOutlineEmail 
                   size={20}
                   className="icon"
                   style={{color:'#000'}}
                 />
               </button>
             </form>
          </div>
          </div>

         </div>
       </div>
  )
}

export default Hero